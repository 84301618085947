/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConferenceCompany } from '../models/ConferenceCompany';
import type { ListResult } from '../models/ListResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConferenceCompanyMasterService {

  /**
   * Get conference company
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterConferenceCompanies({
    keyword,
    expand,
    cciCode,
    page = 1,
    pageSize = 10,
    sort,
    conferenceId,
    companyId,
    answerContent,
  }: {
    keyword?: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    cciCode?: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    conferenceId?: string,
    companyId?: string,
    answerContent?: string,
  }): CancelablePromise<(ListResult & {
    Items?: Array<ConferenceCompany>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/conference_companies',
      query: {
        'keyword': keyword,
        'expand': expand,
        'cci_code': cciCode,
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'conference_id': conferenceId,
        'company_id': companyId,
        'answer_content': answerContent,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
