/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataObject = {
  properties: {
    cci_code: {
      type: 'string',
    },
    id: {
      type: 'string',
    },
    data_id: {
      type: 'string',
    },
    pk: {
      type: 'string',
    },
    sk: {
      type: 'string',
    },
    data_type: {
      type: 'string',
    },
    version: {
      type: 'number',
      isRequired: true,
    },
    is_active: {
      type: 'boolean',
    },
  },
} as const;
