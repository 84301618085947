/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Conference } from '../models/Conference';
import type { ListResult } from '../models/ListResult';
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConferenceMasterService {

  /**
   * Search for conferences
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterConferences({
    keyword,
    expand,
    cciCode,
    page = 1,
    pageSize = 10,
    sort,
    fields,
    memberId,
    onlySubConferences,
    eventDateGte,
    eventDateLte,
    answerStatus,
    hiddenHappened,
  }: {
    keyword?: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    cciCode?: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
    memberId?: string,
    onlySubConferences?: string,
    eventDateGte?: string,
    eventDateLte?: string,
    answerStatus?: number,
    hiddenHappened?: boolean,
  }): CancelablePromise<(ListResult & {
    Items?: Array<Conference>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/conferences',
      query: {
        'keyword': keyword,
        'expand': expand,
        'cci_code': cciCode,
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'fields': fields,
        'member_id': memberId,
        'only_sub_conferences': onlySubConferences,
        'event_date__gte': eventDateGte,
        'event_date__lte': eventDateLte,
        'answer_status': answerStatus,
        'hidden_happened': hiddenHappened,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Add conferences
   * @returns Conference OK
   * @throws ApiError
   */
  public static postV1CciAdminMasterConferences({
    requestBody,
  }: {
    requestBody: Conference,
  }): CancelablePromise<Conference> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/admin/master/conferences',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Get conference detail
   * @returns Conference OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterConferences1({
    id,
    expand,
  }: {
    id: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
  }): CancelablePromise<Conference> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/conferences/{id}',
      path: {
        'id': id,
      },
      query: {
        'expand': expand,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * update conference data
   * @returns Task OK
   * @throws ApiError
   */
  public static putV1CciAdminMasterConferences({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: Conference,
  }): CancelablePromise<Task> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/cci/admin/master/conferences/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * reset sort members
   * @returns Task OK
   * @throws ApiError
   */
  public static putV1CciAdminMasterConferencesResetSortMembers({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: Conference,
  }): CancelablePromise<Task> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/cci/admin/master/conferences/{id}/reset_sort_members',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
