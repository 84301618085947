/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Task = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      status: {
        type: 'string',
      },
      artifacts: {
        type: 'any[]',
      },
      result: {
        properties: {
        },
      },
      created_at: {
        type: 'string',
      },
      created_by: {
        type: 'string',
      },
      attributes: {
        properties: {
          result: {
            properties: {
            },
          },
          request_data: {
            properties: {
            },
          },
        },
      },
    },
  }],
} as const;
