import {UserInfoService} from 'api'

export const useUserSession = () => {
    return {
        createCciSession,
        createCompanySession,
    }
}

const createCciSession = async () => {
    const response = await UserInfoService.getV1CciUserInfo()
    return response
}
const createCompanySession = async () => {
    const response = await UserInfoService.getV1CompanyUserInfo()
    return response
}
