/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConferenceMember } from '../models/ConferenceMember';
import type { ListResult } from '../models/ListResult';
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConferenceMemberCompanyService {

  /**
   * Get conference detail
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CompanyConferenceMembers({
    keyword,
    expand,
    cciCode,
    page = 1,
    pageSize = 10,
    sort,
    conferenceId,
    companyId,
    category,
  }: {
    keyword?: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    cciCode?: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    conferenceId?: string,
    companyId?: string,
    category?: string,
  }): CancelablePromise<(ListResult & {
    Items?: Array<ConferenceMember>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/company/conference_members',
      query: {
        'keyword': keyword,
        'expand': expand,
        'cci_code': cciCode,
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'conference_id': conferenceId,
        'company_id': companyId,
        'category': category,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Answer conference member
   * @returns Task OK
   * @throws ApiError
   */
  public static postV1CompanyConferenceMembersAnswer({
    requestBody,
  }: {
    requestBody: Array<{
      /**
       * id of conference member
       */
      member_id?: string;
      /**
       * id of conference
       */
      conference_id?: string;
      /**
       * no_email of conference member
       */
      no_email?: boolean;
      /**
       * version of conference member
       */
      version?: number;
      /**
       * answer of conference member
       */
      answer: {
        /**
         * answer of conference member
         */
        answer?: string;
        /**
         * answered_at of conference member
         */
        answered_at?: string;
        /**
         * remarks of conference member
         */
        remarks?: string;
        /**
         * delegation_to of conference member
         */
        delegation_to?: string;
        /**
         * delegation_to_name of conference member
         */
        delegation_to_name?: string;
        /**
         * delegation_to_name of conference member
         */
        delegation_to_company_name?: string;
        /**
         * delegation_date of conference member
         */
        delegation_date?: string;
        /**
         * delegation_file of conference member
         */
        delegation_file?: string;
        /**
         * delegation_confirm_time of conference member
         */
        delegation_confirm_time?: string;
      };
      /**
       * representative of conference member
       */
      representative?: {
        /**
         * company_name of conference member
         */
        company_name?: string;
        /**
         * name of conference member
         */
        name?: string;
        /**
         * name_kana of conference member
         */
        name_kana?: string;
        /**
         * company_position_name of conference member
         */
        company_position_name?: string;
        /**
         * answer of conference member
         */
        answer?: {
          /**
           * answer of conference member
           */
          answer?: string;
          /**
           * answered_at of conference member
           */
          answered_at?: string;
        };
      };
    }>,
  }): CancelablePromise<Task> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/company/conference_members/answer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
