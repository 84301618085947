import { Auth } from 'aws-amplify'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import Router from 'next/router'
import * as rax from 'retry-axios'

// NOTE : swagger gen and axios always on top, do not circle includes
// const basePath = ''

const defaultConfig = {
  timeout: 30000,
  raxConfig: {
    retry: 3,
    noResponseRetries: 2,
    retryDelay: 100,
    httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],
    statusCodesToRetry: [
      [100, 199],
      [409, 429],
      [500, 599],
    ],
    backoffType: 'exponential',
    onRetryAttempt: (err: AxiosError) => {
      const cfg = rax.getConfig(err)
      // eslint-disable-next-line no-console
      console.log(`Retry attempt #${cfg?.currentRetryAttempt}`)
    },
  },
}

const addRequestInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (config: AxiosRequestConfig<any>) => {
      try {
        const accessToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        config.headers['Authorization'] = `Bearer ${accessToken}`
      } catch (error) {
        console.error(error)
      }
      return config
    },
    /* istanbul ignore next */
    //TODO: cannot mock request error
    (error: any) => {
      // console.error(getErrorMsg(error))
      console.error(error)

      return Promise.reject(error)
    }
  )
}

const addResponseInterceptors = (instance) => {
  instance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error?.response?.status === 404) {
        let redirectTo404 = true;
        if (error?.config?.url) {
          const parser = new URL(error?.config?.url);
          switch (parser.pathname) {
            case '/v1/company/common/users/forget_password':
              redirectTo404 = false;
              break;
          }
        }
        if (redirectTo404) {
          Router.push('/404')
        }
        return Promise.reject(error)
      // } else if (error?.code === 'ERR_NETWORK') {
      //   return error
      } else {
        return Promise.reject(error)
      }
    }
  )
}

const createApiPjc = (config: any = defaultConfig) => {
  const instance = axios.create(config)
  addRequestInterceptors(instance)
  addResponseInterceptors(instance)
  rax.attach(instance)

  return instance
}

/**
 * api with interceptors
 */

const apiPjc = createApiPjc()

export * from 'axios'
export default apiPjc
