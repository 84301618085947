export const daysOfWeek = [
  {
    id: 1,
    name: '月',
  },
  {
    id: 2,
    name: '火',
  },
  {
    id: 3,
    name: '水',
  },
  {
    id: 4,
    name: '木',
  },
  {
    id: 5,
    name: '金',
  },
  {
    id: 6,
    name: '土',
  },
  {
    id: 0,
    name: '日',
  },
]
