import { action, makeObservable, observable, runInAction } from 'mobx'

import { RootStore } from '.'

export type CounterHydration = {
  start: number
}

export class CounterStore {
  root: RootStore
  size: 'BIG' | 'SMALL' = 'SMALL'
  state: 'STOPPED' | 'STARTED' | 'PAUSED' = 'STOPPED'
  counter = 0
  timer: number | undefined
  constructor(root: RootStore) {
    this.root = root
    makeObservable(this, {
      stop: action,
      hydrate: action,
      size: observable,
      state: observable,
      counter: observable,
    })
  }

  start() {
    this.startInterval()
  }

  stop() {
    this.state = 'STOPPED'
    clearInterval(this.timer)
  }

  protected startInterval() {
    this.state = 'STARTED'
    this.timer = window.setInterval(() => {
      runInAction(() => {
        this.counter += 1
      })
    }, 100)
  }

  hydrate(data?: CounterHydration) {
    if (data) {
      this.counter = data.start
    }
  }
}
