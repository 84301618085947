/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserMasterService {

  /**
   * get user
   * @returns User OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterUsers({
    username,
    expand,
  }: {
    username: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
  }): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/users/{username}',
      path: {
        'username': username,
      },
      query: {
        'expand': expand,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * update user
   * @returns User OK
   * @throws ApiError
   */
  public static putV1CciAdminMasterUsers({
    username,
    requestBody,
  }: {
    username: string,
    requestBody: User,
  }): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/cci/admin/master/users/{username}',
      path: {
        'username': username,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
