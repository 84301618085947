import { action, makeObservable, observable } from 'mobx'

import { RootStore } from '.'

export type LayoutHydration = {
  isOpen: boolean
}

export enum LayoutType {
  GUEST = 'guest',
  USER = 'user',
  COMPANY = 'company',
  ADMIN = 'admin',
}

export class LayoutStore {
  root: RootStore
  isOpen: boolean
  layout = LayoutType.GUEST

  constructor(root: RootStore) {
    this.root = root
    makeObservable(this, {
      open: action.bound,
      close: action.bound,
      setLayout: action.bound,
      layout: observable,
      isOpen: observable,
    })
  }

  setLayout(layout: LayoutType) {
    this.layout = layout
  }

  open() {
    this.isOpen = true
  }

  close() {
    this.isOpen = false
  }

  hydrate(data?: LayoutHydration) {
    if (data) {
      this.isOpen = data.isOpen
    }
  }
}
