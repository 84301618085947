/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Conference } from '../models/Conference';
import type { ListResult } from '../models/ListResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConferenceCompanyService {

  /**
   * Get conference list
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CompanyConferences({
    keyword,
    expand,
    cciCode,
    page = 1,
    pageSize = 10,
    sort,
    status,
    answerStatus,
    hiddenHappened,
  }: {
    keyword?: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    cciCode?: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    status?: number,
    answerStatus?: number,
    hiddenHappened?: boolean,
  }): CancelablePromise<(ListResult & {
    Items?: Array<Conference>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/company/conferences',
      query: {
        'keyword': keyword,
        'expand': expand,
        'cci_code': cciCode,
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'status': status,
        'answer_status': answerStatus,
        'hidden_happened': hiddenHappened,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Get conference detail
   * @returns Conference OK
   * @throws ApiError
   */
  public static getV1CompanyConferences1({
    id,
    expand,
  }: {
    id: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
  }): CancelablePromise<Conference> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/company/conferences/{id}',
      path: {
        'id': id,
      },
      query: {
        'expand': expand,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
