/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Company = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      company_code: {
        type: 'string',
        isRequired: true,
      },
      name: {
        type: 'string',
        isRequired: true,
      },
      attributes: {
        properties: {
          contact_tos: {
            type: 'array',
            contains: {
              properties: {
                name: {
                  type: 'string',
                },
                email: {
                  type: 'string',
                },
                phone: {
                  type: 'string',
                },
              },
            },
          },
          original_address: {
            type: 'string',
          },
          zip_code: {
            type: 'string',
          },
          address1: {
            type: 'string',
          },
          address2: {
            type: 'string',
          },
          address_comment: {
            type: 'string',
          },
          login_username: {
            type: 'string',
          },
          name_kana: {
            type: 'string',
          },
          name1: {
            type: 'string',
          },
          name2: {
            type: 'string',
          },
          contact_to_name: {
            type: 'string',
          },
          email: {
            type: 'string',
          },
          phone: {
            type: 'string',
          },
          has_user: {
            type: 'boolean',
          },
          old_password: {
            type: 'string',
          },
          new_password: {
            type: 'string',
          },
          new_password_confirmation: {
            type: 'string',
          },
          admin_changed_info_flag: {
            type: 'string',
          },
        },
        isRequired: true,
      },
    },
  }],
} as const;
