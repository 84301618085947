import 'styles/globals.css'
import 'styles/index.scss'
import 'dayjs/locale/ja'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ConfigProvider } from 'antd'
import jaJP from 'antd/lib/locale/ja_JP'
import { Amplify } from 'aws-amplify'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'
import { ReactElement, ReactNode } from 'react'
import { RootStoreHydration } from 'src/stores'
import { RootStoreProvider } from 'src/stores/provider'

import awsconfig from '../aws-exports'
import {SessionManagerProvider} from "../common/contexts/session_manager";
Amplify.configure({ ...awsconfig, ssr: true })

export type NextPageWithLayout<P = any> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  pageProps: AppProps['pageProps'] & {
    hydrationData: RootStoreHydration
  }
}

function MyApp({ Component, pageProps }: AppPropsWithLayout): ReactNode {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <RootStoreProvider hydrationData={pageProps.hydrationData}>
      <SessionManagerProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <ConfigProvider locale={jaJP}>{getLayout(<Component {...pageProps} />)}</ConfigProvider>
        </LocalizationProvider>
      </SessionManagerProvider>
    </RootStoreProvider>
  )
}

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => {
  NProgress.done()
})
Router.events.on('routeChangeError', () => NProgress.done())

export default MyApp
