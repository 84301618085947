/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MasterData = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      master_code: {
        type: 'string',
        isRequired: true,
      },
      master_type_code: {
        type: 'string',
        isRequired: true,
      },
      name: {
        type: 'string',
        isRequired: true,
      },
      raw_data: {
        properties: {
        },
      },
      attributes: {
        properties: {
          order_no: {
            type: 'string',
          },
          list_type_name: {
            type: 'string',
          },
          display_position: {
            type: 'boolean',
          },
          types: {
            type: 'array',
            contains: {
              properties: {
                type_code: {
                  type: 'string',
                },
                name: {
                  type: 'string',
                },
                order_no: {
                  type: 'string',
                },
              },
            },
          },
          conference_name: {
            type: 'string',
          },
          short_conference_name: {
            type: 'string',
          },
          default_sort: {
            type: 'number',
          },
          chairman_name: {
            type: 'string',
          },
          note1: {
            type: 'string',
          },
          note2: {
            type: 'string',
          },
          email: {
            type: 'string',
          },
          sub_conferences: {
            type: 'array',
            contains: {
              properties: {
                name: {
                  type: 'string',
                },
              },
            },
          },
          conference_members: {
            type: 'array',
            contains: {
              properties: {
                name: {
                  type: 'string',
                },
                member_id: {
                  type: 'string',
                },
                position_type: {
                  type: 'string',
                },
                position_name: {
                  type: 'string',
                },
                department_name: {
                  type: 'string',
                },
                delegation_letter: {
                  type: 'boolean',
                },
                not_agency_attendance: {
                  type: 'boolean',
                },
              },
            },
          },
        },
        isRequired: true,
      },
    },
  }],
} as const;
