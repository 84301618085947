/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Member = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      company_code: {
        type: 'string',
      },
      code: {
        type: 'string',
        isRequired: true,
      },
      name: {
        type: 'string',
        isRequired: true,
      },
      attributes: {
        properties: {
          company_position_name: {
            type: 'string',
          },
          name_kana: {
            type: 'string',
          },
          is_foreign_name: {
            type: 'boolean',
          },
          name2: {
            type: 'string',
          },
        },
        isRequired: true,
      },
      positions: {
        type: 'array',
        contains: {
          type: 'MemberPosition',
        },
      },
      company: {
        type: 'Company',
        description: `required when expands include \`company\``,
      },
    },
  }],
} as const;
