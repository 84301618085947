/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SubConference = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      name: {
        type: 'string',
        isRequired: true,
      },
      conference_members: {
        type: 'array',
        contains: {
          type: 'ConferenceMember',
        },
      },
      attributes: {
        properties: {
          conference_sequence_no: {
            type: 'string',
          },
          parent_id: {
            type: 'string',
          },
          report: {
            properties: {
              number_of_invited_member: {
                type: 'number',
              },
              number_of_representative: {
                type: 'number',
              },
              number_of_real_member: {
                type: 'number',
              },
              number_of_online_member: {
                type: 'number',
              },
              number_of_not_member: {
                type: 'number',
              },
              number_of_unanswered: {
                type: 'number',
              },
              number_of_needed_delegation_letter: {
                type: 'number',
              },
              number_of_sent_delegation_letter: {
                type: 'number',
              },
            },
          },
        },
      },
    },
  }],
} as const;
