import dayjs, { Dayjs } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
dayjs.locale('ja')
dayjs.extend(weekday)
dayjs.extend(localeData)

export const Format = {
  date: 'YYYY/MM/DD',
  dateJp: 'YYYY年MM月DD日',
  datetime: 'YYYY-MM-DD HH:mm:ss',
  dateTimeJp: 'YYYY年MM月DD日 HH時mm分',
  dateTime: 'YYYY/MM/DD HH:mm',
  dateISO: 'YYYY-MM-DD',
  dateTimeISO: 'YYYY-MM-DD HH:mm',
  time: 'HH:mm',
  dateBody: 'YYYYMMDD',
}

export const Formatter = {
  date: (date?: string | Date | Dayjs | null, isJp = false): string =>
    !date ? '' : dayjs(date).format(isJp ? Format.dateJp : Format.date),
  dateTime: (date?: string | Date | Dayjs | null): string => (!date ? '' : dayjs(date).format(Format.dateTime)),
  dateBody: (date?: string | Date | Dayjs | null): string => (!date ? '' : dayjs(date).format(Format.dateBody)),
  dateISO: (date?: string | Date | Dayjs | null): string => (!date ? '' : dayjs(date).format(Format.dateISO)),
  dateTimeISO: (date?: string | Date | Dayjs | null): string => (!date ? '' : dayjs(date).format(Format.dateTimeISO)),
  dateTimeJp: (date?: string | Date | Dayjs | null): string => (!date ? '' : dayjs(date).format(Format.dateTimeJp)),
  time: (date?: string | Date | Dayjs | null): string => (!date ? '' : dayjs(date).format(Format.time)),
}
