/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Conference = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      company_code: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      code: {
        type: 'string',
      },
      attributes: {
        properties: {
          detail_answer_status: {
            type: 'number',
          },
          delegation_letter_status: {
            type: 'number',
          },
          answer_status: {
            type: 'number',
          },
          conference_sequence_no: {
            type: 'string',
          },
          short_name: {
            type: 'string',
          },
          response_due_date: {
            type: 'string',
          },
          start_event_time: {
            type: 'string',
          },
          end_event_time: {
            type: 'string',
          },
          status: {
            type: 'number',
          },
          published_at: {
            type: 'string',
          },
          default_sort: {
            type: 'number',
          },
          category: {
            type: 'number',
          },
          address: {
            type: 'string',
          },
          outline: {
            type: 'string',
          },
          overview_s3_url: {
            type: 'string',
          },
          chairman_name: {
            type: 'string',
          },
          note1: {
            type: 'string',
          },
          note2: {
            type: 'string',
          },
          email: {
            type: 'string',
          },
          report: {
            properties: {
              number_of_invited_members: {
                type: 'number',
              },
              number_of_representatives: {
                type: 'number',
              },
              number_of_real_members: {
                type: 'number',
              },
              number_of_online_members: {
                type: 'number',
              },
              number_of_not_members: {
                type: 'number',
              },
              number_of_unanswered_members: {
                type: 'number',
              },
              number_of_needed_delegation_letters: {
                type: 'number',
              },
              number_of_sent_delegation_letters: {
                type: 'number',
              },
              number_of_real_representative_members: {
                type: 'number',
              },
              number_of_online_representative_members: {
                type: 'number',
              },
              number_of_not_representative_members: {
                type: 'number',
              },
            },
          },
        },
        isRequired: true,
      },
      conference_members: {
        type: 'array',
        contains: {
          type: 'ConferenceMember',
        },
      },
      sub_conferences: {
        type: 'array',
        contains: {
          type: 'SubConference',
        },
      },
    },
  }],
} as const;
