/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MasterTypeCode {
  CCI_POSITION_TYPE = 'cci_position_type',
  CCI_OFFICER = 'CCI_OFFICER',
  CCI_SECTION = 'CCI_SECTION',
  CCI_BRANCH = 'CCI_BRANCH',
  CCI_COMMITTEE = 'CCI_COMMITTEE',
  CCI_WOMEN_ASSOCIATION = 'CCI_WOMEN_ASSOCIATION',
  CCI_YOUTH_DIVISION = 'CCI_YOUTH_DIVISION',
}
