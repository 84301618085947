import {Button, Modal} from "antd";
import React, {createContext, useContext, useEffect, useState} from 'react'

import {UserInfo} from "../../../api";
import {useUserSession} from "../../containers/user-session/controller";
import {useAuthStore} from "../../stores/hooks";

interface ISessionManagerProps {
    children: React.ReactNode
}

interface SessionManagerType {
    isCciAdmin: boolean,
    isCciConferenceUser: boolean,
    isCompanyAdmin: boolean,
    loading: number,
    setUserSession?: React.Dispatch<React.SetStateAction<UserInfo>>,
    userSession?: UserInfo,
    createSession?: () => void,
}

const initUserSession: SessionManagerType = {
    loading: 0, isCciAdmin: false, isCciConferenceUser: false, isCompanyAdmin: false
}

const SessionManagerContext = createContext<SessionManagerType>(initUserSession)

function SessionManagerProvider({children}: ISessionManagerProps) {
    const store = useAuthStore()
    const [loading, setLoading] = useState<number>(0)

    const [userSession, setUserSession] = useState<UserInfo>({
        cci_code: null,
        username: null,
        user_type: null,
    })

    const [modalUserSessionError, setModalUserSessionError] = useState(false);

    const isCciAdmin: boolean = userSession.user_type == 'CCI_ADMIN';
    const isCciConferenceUser: boolean = userSession.user_type == 'CCI_CONFERENCE_USER';
    const isCompanyAdmin: boolean = userSession.user_type == 'COMPANY_ADMIN';

    const clearUserSession = () => {
        setUserSession({
            cci_code: null,
            username: null,
            user_type: null,
        })
    }

    const userSessionController = useUserSession()

    useEffect(() => {
        if (store.isLoggedIn) {
            createSession()
        } else {
            clearUserSession()
        }
    }, [store.isLoggedIn]);

    const createSession = () => {
        setLoading((prev) => {
            return prev + 1
        })

        if (store.isLoggedIn) {
            switch (store.role) {
                case 'CCI_ADMIN':
                case 'CCI_CONFERENCE_USER':
                    userSessionController
                        .createCciSession()
                        .then((response) => {
                            setUserSession(response)
                        }).catch(() => {
                        setModalUserSessionError(true)
                    }).finally(() => {
                        setLoading((prev) => {
                            return prev - 1
                        })
                    })
                    break;
                case 'COMPANY_ADMIN':
                    userSessionController
                        .createCompanySession()
                        .then((response) => {
                            setUserSession(response)
                        })
                        .finally(() => {
                            setLoading((prev) => {
                                return prev - 1
                            })
                        })
                    break;
            }
        }
    }

    const logout = () => {
        store.logout().then(() => {
            setModalUserSessionError(false)
        })
    }

    return (
        <SessionManagerContext.Provider value={{
            userSession,
            setUserSession,
            loading,
            isCciAdmin,
            isCciConferenceUser,
            isCompanyAdmin,
            createSession,
        }}>
            <>
                <Modal
                    centered
                    open={modalUserSessionError}
                    onOk={() => setModalUserSessionError(false)}
                    title={null}
                    footer={null}
                    closeIcon={null}
                >
                    <div className='mt-5 mb-5 flex justify-center'>
                        <h2>ユーザ情報取得時にエラーが発生しました。</h2>
                    </div>
                    <div className='mt-5 mb-5 flex justify-center'>
                        <Button onClick={logout}>ログオフ</Button>
                    </div>
                </Modal>
                {children}
            </>
        </SessionManagerContext.Provider>
    )
}

function useSessionManager(): SessionManagerType {
    const context = useContext(SessionManagerContext)
    if (!context) {
        throw new Error('No Master context. Did you forget to wrap your app in a <SessionManagerProvider />?')
    }
    return context
}

export {SessionManagerProvider, useSessionManager}
