import { AuthStore } from './auth'
import { CounterHydration, CounterStore } from './counter'
import { CourseStore } from './course'
// import { EmployeeStore } from './employee'
import { LayoutStore } from './layout'

export type RootStoreHydration = {
  stopwatchStore?: CounterHydration
}
export class RootStore {
  authStore: AuthStore
  counterStore: CounterStore
  layoutStore: LayoutStore

  courseStore: CourseStore

  // employeeStore: EmployeeStore
  constructor() {
    this.counterStore = new CounterStore(this)
    this.layoutStore = new LayoutStore(this)
    this.authStore = new AuthStore(this)
    this.courseStore = new CourseStore(this)
    // this.employeeStore = new EmployeeStore(this)
  }

  hydrate(data: RootStoreHydration) {
    if (data.stopwatchStore) {
      this.counterStore.hydrate(data.stopwatchStore)
    }
  }
}
