import { Auth } from 'aws-amplify'
import Cookies from 'js-cookie'
import { action, makeObservable, observable } from 'mobx'
import Router from 'next/router'
import { checkRedirect } from 'src/common/helpers'
import { COOKIE_KEY } from 'src/common/open-api/cookie'
import { ROLE } from 'src/types/role'

import { CompanyCompanyService } from './../../api/services/CompanyCompanyService'
import { RootStore } from '.'

export type LayoutHydration = {
  isOpen: boolean
}

export class AuthStore {
  root: RootStore
  isLoggedIn: boolean

  role: ROLE

  companyName: string

  constructor(root: RootStore) {
    this.root = root
    makeObservable(this, {
      checkAuth: action.bound,
      login: action.bound,
      logout: action.bound,
      isLoggedIn: observable,
      role: observable,
      companyName: observable,
    })
  }

  checkAuth() {
    const role = Cookies.get(COOKIE_KEY.role)
    const isLoggedIn = !!Cookies.get(COOKIE_KEY.logged_in)
    const companyName = Cookies.get(COOKIE_KEY.company_name)

    this.role = role as ROLE
    this.isLoggedIn = !!isLoggedIn
    this.companyName = companyName
  }

  async login(data) {
    const response = await Auth.signIn(data.email, data.password)
    let companyResp = undefined
    // set company name
    if (response.attributes['custom:company_id']) {
      companyResp = await CompanyCompanyService.getV1CompanyCompanies({
        id: response.attributes['custom:company_id'],
      })
      this.companyName = companyResp.attributes?.name1 || ''
      Cookies.set(COOKIE_KEY.company_name, companyResp.attributes?.name1 || '')
    }

    const roleType = response.attributes['custom:user_type']
    this.role = roleType
    this.isLoggedIn = true

    Cookies.set(COOKIE_KEY.role, roleType)
    Cookies.set(COOKIE_KEY.logged_in, 'true')

    if (response) {
      const { prev } = Router.query

      // Check change password yet?
      if (roleType === ROLE.COMPANY) {
        const companyAttributes = companyResp.attributes || {}

        let hasChangePassword = undefined

        const adminChangedInfoFlag = companyAttributes.admin_changed_info_flag

        if (adminChangedInfoFlag === '1') {
          hasChangePassword = true
        }

        const hasAddEmail = companyAttributes.email ? false : true

        if (hasChangePassword || hasAddEmail) {
          let linkToConfirmAccount = `/company/confirm-account?version=${
            companyResp.version
          }&company_id=${encodeURIComponent(companyResp.id)}`
          if (hasChangePassword) linkToConfirmAccount = linkToConfirmAccount + '&hasChangePassword=true'
          if (hasAddEmail) linkToConfirmAccount = linkToConfirmAccount + '&hasAddEmail=true'
          // Cookies.set(COOKIE_KEY.link_to_confirm_account, linkToConfirmAccount)
          Router.push(linkToConfirmAccount)
          return
        }
      }

      if (prev) {
        Router.push(prev.toString().replaceAll('#', '%23'))
      } else {
        const pathName = checkRedirect(roleType)
        Router.push(pathName)
      }
    }
  }

  async logout() {
    await Auth.signOut()
    Cookies.remove(COOKIE_KEY.role)
    Cookies.remove(COOKIE_KEY.logged_in)
    Cookies.remove(COOKIE_KEY.link_to_confirm_account)
    Cookies.remove(COOKIE_KEY.company_name)
    this.isLoggedIn = false
    this.companyName = null
    Router.push('/login')
  }
}
