import { action, makeObservable, observable } from 'mobx'

import { RootStore } from '.'

export class CourseStore {
  root: RootStore
  openComment = false
  openChat = false
  openInfo = false
  openModalCommentCompleted = false

  constructor(root: RootStore) {
    this.root = root
    makeObservable(this, {
      toggleComment: action.bound,
      toggleChat: action.bound,
      toggleInfo: action.bound,
      toggleModalCommentCompleted: action.bound,

      openComment: observable,
      openChat: observable,
      openInfo: observable,
      openModalCommentCompleted: observable,
    })
  }

  toggleComment() {
    this.openComment = !this.openComment
  }

  toggleChat() {
    this.openChat = !this.openChat
  }

  toggleInfo() {
    this.openInfo = !this.openInfo
  }

  toggleModalCommentCompleted() {
    this.openModalCommentCompleted = !this.openModalCommentCompleted
  }
}
