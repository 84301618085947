/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MemberPosition = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      company_code: {
        type: 'string',
      },
      code: {
        type: 'string',
        description: `member's code`,
      },
      start_at: {
        type: 'string',
        format: 'date',
      },
      end_at: {
        type: 'string',
        format: 'date',
      },
      attributes: {
        properties: {
          display_position: {
            type: 'boolean',
          },
          position_type: {
            type: 'string',
          },
          department_name: {
            type: 'string',
          },
          position_name: {
            type: 'string',
          },
          position_order_no: {
            type: 'string',
          },
          officer_sequence_no: {
            type: 'string',
          },
        },
      },
      company: {
        type: 'Company',
        description: `required when expands include \`company\``,
      },
      member: {
        type: 'Member',
        description: `required when expands include \`member\``,
      },
    },
  }],
} as const;
