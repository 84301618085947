/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserInfo } from '../models/UserInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserInfoService {

  /**
   * Get User Information for CCI User
   * @returns UserInfo OK
   * @throws ApiError
   */
  public static getV1CciUserInfo(): CancelablePromise<UserInfo> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/user_info',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Get User Information for Company User
   * @returns UserInfo OK
   * @throws ApiError
   */
  public static getV1CompanyUserInfo(): CancelablePromise<UserInfo> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/company/user_info',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
