/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ConferenceMember = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      company_code: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      code: {
        type: 'string',
      },
      is_representative: {
        type: 'boolean',
      },
      attributes: {
        properties: {
          conference_id: {
            type: 'string',
          },
          member_id: {
            type: 'string',
          },
          position_type: {
            type: 'string',
          },
          department_name: {
            type: 'string',
          },
          position_name: {
            type: 'string',
          },
          position_name_for_company: {
            type: 'string',
          },
          display_position: {
            type: 'boolean',
          },
          delegation_letter: {
            type: 'boolean',
          },
          not_agency_attendance: {
            type: 'boolean',
          },
          order_no: {
            type: 'string',
          },
          company_name: {
            type: 'string',
          },
          company_position_name: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          name_kana: {
            type: 'string',
          },
          no_email: {
            type: 'boolean',
          },
          member_data: {
            properties: {
              name: {
                type: 'string',
              },
              name_kana: {
                type: 'string',
              },
              company_name: {
                type: 'string',
              },
              company_name_kana: {
                type: 'string',
              },
              company_position_name: {
                type: 'string',
              },
              position_name: {
                type: 'string',
              },
            },
          },
          answer: {
            properties: {
              answer: {
                type: 'string',
              },
              answered_at: {
                type: 'string',
              },
              remarks: {
                type: 'string',
              },
              delegation_date: {
                type: 'string',
              },
              delegation_to: {
                type: 'string',
              },
              delegation_to_name: {
                type: 'string',
              },
              delegation_file: {
                type: 'string',
              },
              delegation_confirm_time: {
                type: 'string',
              },
            },
          },
        },
        isRequired: true,
      },
      member: {
        type: 'Member',
        description: `required when expands include \`member\``,
      },
      representative: {
        type: 'RepresentativeConferenceMember',
        description: `required when expands include \`representative\``,
      },
    },
  }],
} as const;
