/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $User = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      user_code: {
        type: 'string',
      },
      username: {
        type: 'string',
      },
      user_type: {
        type: 'string',
      },
      email: {
        type: 'string',
      },
      company_code: {
        type: 'string',
      },
      attributes: {
        properties: {
          company_id: {
            type: 'string',
          },
          old_password: {
            type: 'string',
            description: `old password`,
          },
          new_password: {
            type: 'string',
            description: `new password`,
          },
          new_password_confirmation: {
            type: 'string',
            description: `new password confirmation`,
          },
          phone: {
            type: 'string',
          },
          contact_to_name: {
            type: 'string',
          },
          contact_tos: {
            type: 'array',
            contains: {
              properties: {
                name: {
                  type: 'string',
                },
                email: {
                  type: 'string',
                },
                phone: {
                  type: 'string',
                },
              },
            },
          },
        },
        isRequired: true,
      },
    },
  }],
} as const;
