/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ConferenceCompany = {
  type: 'all-of',
  contains: [{
    type: 'DataObject',
  }, {
    properties: {
      name: {
        type: 'string',
      },
      attributes: {
        properties: {
          conference_id: {
            type: 'string',
          },
          company_id: {
            type: 'string',
          },
          delegation_file: {
            type: 'string',
          },
          overview_file: {
            type: 'string',
          },
        },
        isRequired: true,
      },
    },
  }],
} as const;
