/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { S3GetObjectUrl } from '../models/S3GetObjectUrl';
import type { S3PutObjectUrl } from '../models/S3PutObjectUrl';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommonService {

  /**
   * Get s3 put object url
   * @returns S3PutObjectUrl OK
   * @throws ApiError
   */
  public static postV1CciCommonS3PutObjectUrl({
    cciCode,
    requestBody,
  }: {
    cciCode: string,
    requestBody: {
      /**
       * path of s3 object
       */
      path: string;
    },
  }): CancelablePromise<S3PutObjectUrl> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/{cci_code}/common/s3/put_object_url',
      path: {
        'cci_code': cciCode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Get s3 get object url
   * @returns S3GetObjectUrl OK
   * @throws ApiError
   */
  public static getV1CciCommonS3GetObjectUrl({
    cciCode,
    path,
  }: {
    cciCode: string,
    path?: string,
  }): CancelablePromise<S3GetObjectUrl> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/{cci_code}/common/s3/get_object_url',
      path: {
        'cci_code': cciCode,
      },
      query: {
        'path': path,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Get s3 get object url
   * @returns S3GetObjectUrl OK
   * @throws ApiError
   */
  public static getV1CompanyCommonS3GetObjectUrl({
    cciCode,
    path,
  }: {
    cciCode: string,
    path?: string,
  }): CancelablePromise<S3GetObjectUrl> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/company/{cci_code}/common/s3/get_object_url',
      path: {
        'cci_code': cciCode,
      },
      query: {
        'path': path,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
