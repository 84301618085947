/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResult } from '../models/ListResult';
import type { MasterData } from '../models/MasterData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConferenceTemplateMasterService {

  /**
   * get conference template list
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciMasterCciConferenceTemplate({
    cciCode,
    page = 1,
    pageSize = 10,
    sort,
    expand,
    keyword,
    fields,
    name,
    conferenceTemplateCode,
  }: {
    cciCode: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    keyword?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
    name?: string,
    conferenceTemplateCode?: string,
  }): CancelablePromise<(ListResult & {
    Items?: Array<MasterData>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/{cci_code}/master/CCI_CONFERENCE_TEMPLATE',
      path: {
        'cci_code': cciCode,
      },
      query: {
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'expand': expand,
        'keyword': keyword,
        'fields': fields,
        'name': name,
        'conference_template_code': conferenceTemplateCode,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * create conference template
   * @returns MasterData OK
   * @throws ApiError
   */
  public static postV1CciMasterCciConferenceTemplate({
    cciCode,
    requestBody,
  }: {
    cciCode: string,
    requestBody?: any,
  }): CancelablePromise<MasterData> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/{cci_code}/master/CCI_CONFERENCE_TEMPLATE',
      path: {
        'cci_code': cciCode,
      },
      body: requestBody,
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * update conference template
   * @returns MasterData OK
   * @throws ApiError
   */
  public static putV1CciMasterCciConferenceTemplate({
    cciCode,
    id,
    requestBody,
  }: {
    cciCode: string,
    id: string,
    requestBody?: any,
  }): CancelablePromise<MasterData> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/cci/{cci_code}/master/CCI_CONFERENCE_TEMPLATE/{id}',
      path: {
        'cci_code': cciCode,
        'id': id,
      },
      body: requestBody,
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * delete conference template
   * @returns MasterData OK
   * @throws ApiError
   */
  public static deleteV1CciMasterCciConferenceTemplate({
    cciCode,
    id,
    requestBody,
  }: {
    cciCode: string,
    id: string,
    requestBody?: any,
  }): CancelablePromise<MasterData> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/cci/{cci_code}/master/CCI_CONFERENCE_TEMPLATE/{id}',
      path: {
        'cci_code': cciCode,
        'id': id,
      },
      body: requestBody,
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * get conference template
   * @returns MasterData OK
   * @throws ApiError
   */
  public static getV1CciMasterCciConferenceTemplate1({
    cciCode,
    id,
    expand,
    fields,
  }: {
    cciCode: string,
    id: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
  }): CancelablePromise<MasterData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/{cci_code}/master/CCI_CONFERENCE_TEMPLATE/{id}',
      path: {
        'cci_code': cciCode,
        'id': id,
      },
      query: {
        'expand': expand,
        'fields': fields,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
