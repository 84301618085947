var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"77c911ff6392db12c2125038934b68ca4063587d"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/browser'

const isInitSentry = process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local'

isInitSentry &&
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: process.env.NEXT_PUBLIC_REPLAYS_SESSION_SAMPLE_RATE,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: process.env.NEXT_PUBLIC_REPLAYS_ON_ERROR_SAMPLE_RATE,
    integrations: [new Sentry.Replay()],
  })
