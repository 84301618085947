/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PasswordService {

  /**
   * Forget password
   * @returns any OK
   * @throws ApiError
   */
  public static postV1CompanyCommonUsersForgetPassword({
    requestBody,
  }: {
    requestBody: {
      username?: string;
      email?: string;
    },
  }): CancelablePromise<{
    message?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/company/common/users/forget_password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Reset password
   * @returns any OK
   * @throws ApiError
   */
  public static postV1CompanyCommonUsersResetPassword({
    requestBody,
  }: {
    requestBody: {
      username?: string;
      email?: string;
      new_password?: string;
      new_password_confirmation?: string;
      reset_password_token?: string;
    },
  }): CancelablePromise<{
    message?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/company/common/users/reset_password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
