/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListResult = {
  properties: {
    Count: {
      type: 'number',
      description: `total number of items`,
      isRequired: true,
    },
    Page: {
      type: 'number',
      description: `current page number`,
    },
    PageSize: {
      type: 'number',
      description: `current page size`,
    },
  },
} as const;
