/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResult } from '../models/ListResult';
import type { Member } from '../models/Member';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberMasterService {

  /**
   * search members (person / employee)
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterMembers({
    startAtGte,
    endAtLte,
    expand,
    companyCode,
    cciCode,
    page = 1,
    pageSize = 10,
    sort,
    fields,
  }: {
    startAtGte?: string,
    endAtLte?: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    companyCode?: string,
    cciCode?: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
  }): CancelablePromise<(ListResult & {
    Items?: Array<Member>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/members',
      query: {
        'start_at__gte': startAtGte,
        'end_at__lte': endAtLte,
        'expand': expand,
        'company_code': companyCode,
        'cci_code': cciCode,
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'fields': fields,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * create a member
   * @returns Member OK
   * @throws ApiError
   */
  public static postV1CciAdminMasterMembers({
    requestBody,
  }: {
    requestBody: Member,
  }): CancelablePromise<Member> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/admin/master/members',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Get member detail
   * @returns Member OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterMembers1({
    id,
    expand,
  }: {
    id: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
  }): CancelablePromise<Member> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/members/{id}',
      path: {
        'id': id,
      },
      query: {
        'expand': expand,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Update member detail
   * @returns Member OK
   * @throws ApiError
   */
  public static putV1CciAdminMasterMembers({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: Member,
  }): CancelablePromise<Member> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/cci/admin/master/members/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * create login user
   * @returns User OK
   * @throws ApiError
   */
  public static postV1CciAdminMasterMembersUser({
    id,
  }: {
    id: string,
  }): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/admin/master/members/{id}/user',
      path: {
        'id': id,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
