/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResult } from '../models/ListResult';
import type { MasterData } from '../models/MasterData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommonMasterService {

  /**
   * search master data
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciMaster({
    masterTypeCode,
    cciCode,
    masterCode,
    masterCodeStartswith,
    page = 1,
    pageSize = 10,
    sort,
    fields,
  }: {
    masterTypeCode: string,
    cciCode: string,
    masterCode?: string,
    masterCodeStartswith?: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
  }): CancelablePromise<(ListResult & {
    Items?: Array<MasterData>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/{cci_code}/master/{master_type_code}',
      path: {
        'master_type_code': masterTypeCode,
        'cci_code': cciCode,
      },
      query: {
        'master_code': masterCode,
        'master_code__startswith': masterCodeStartswith,
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'fields': fields,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * search master data for position type
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciMaster1({
    positionType,
    cciCode,
    page = 1,
    pageSize = 10,
    sort,
    fields,
  }: {
    positionType: string,
    cciCode: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
  }): CancelablePromise<(ListResult & {
    Items?: Array<MasterData>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/{cci_code}/master/{position_type}',
      path: {
        'position_type': positionType,
        'cci_code': cciCode,
      },
      query: {
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'fields': fields,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
