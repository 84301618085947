/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyCompanyService {

  /**
   * Get company detail
   * @returns Company OK
   * @throws ApiError
   */
  public static getV1CompanyCompanies({
    id,
    expand,
  }: {
    id: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
  }): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/company/companies/{id}',
      path: {
        'id': id,
      },
      query: {
        'expand': expand,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Update company
   * @returns Company OK
   * @throws ApiError
   */
  public static putV1CompanyCompanies({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: Company,
  }): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/company/companies/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
