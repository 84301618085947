/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotifierService {

  /**
   * Pusher Auth
   * @returns any OK
   * @throws ApiError
   */
  public static postV1CciCommonPusherAuth({
    requestBody,
  }: {
    requestBody?: {
      channel_name: string;
      socket_id: string;
    },
  }): CancelablePromise<{
    auth: string;
    shared_secret?: string;
    channel_data?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/common/pusher/auth',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * Pusher Auth
   * @returns any OK
   * @throws ApiError
   */
  public static postV1CompanyCommonPusherAuth({
    requestBody,
  }: {
    requestBody?: {
      channel_name: string;
      socket_id: string;
    },
  }): CancelablePromise<{
    auth: string;
    shared_secret?: string;
    channel_data?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/company/common/pusher/auth',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
