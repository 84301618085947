/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResult } from '../models/ListResult';
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportService {

  /**
   * Export data
   * @returns Task OK
   * @throws ApiError
   */
  public static postV1CciExport({
    cciCode,
    requestBody,
  }: {
    cciCode: string,
    requestBody: {
      /**
       * export type
       */
      export_code: string;
      /**
       * member id
       */
      member_id?: string;
      /**
       * position type
       */
      position_type?: string;
      /**
       * department name
       */
      department_name?: string;
      /**
       * position name
       */
      position_name?: string;
      /**
       * company name
       */
      company_name__contains?: string;
      /**
       * start at greater than
       */
      start_at__gte?: string;
      /**
       * end at greater than
       */
      end_at__lte?: string;
      /**
       * conference id
       */
      conference_id?: string;
      category?: string;
      /**
       * answer content
       */
      answer_content?: string;
      /**
       * sort
       */
      sort?: string;
      /**
       * options
       */
      options?: {
        /**
         * file name params
         */
        file_name_params?: any[];
      };
    },
  }): CancelablePromise<Task> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/{cci_code}/export',
      path: {
        'cci_code': cciCode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * list export data
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciExport({
    cciCode,
    expand,
    fields,
    sort,
    page = 1,
    pageSize = 10,
    createdBy,
    status,
  }: {
    cciCode: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    page?: number,
    pageSize?: number,
    createdBy?: string,
    status?: string,
  }): CancelablePromise<(ListResult & {
    Items?: Array<Task>;
  })> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/{cci_code}/export',
      path: {
        'cci_code': cciCode,
      },
      query: {
        'expand': expand,
        'fields': fields,
        'sort': sort,
        'page': page,
        'page_size': pageSize,
        'created_by': createdBy,
        'status': status,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

  /**
   * get export data
   * @returns Task OK
   * @throws ApiError
   */
  public static getV1CciExport1({
    cciCode,
    id,
    expand,
    fields,
  }: {
    cciCode: string,
    id: string,
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
  }): CancelablePromise<Task> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/{cci_code}/export/{id}',
      path: {
        'cci_code': cciCode,
        'id': id,
      },
      query: {
        'expand': expand,
        'fields': fields,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
