/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterData } from '../models/MasterData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GeneralMasterCompanyService {

  /**
   * Get master data
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CompanyMaster({
    cciCode,
    masterTypeCode,
    masterCode,
    masterCodeStartswith,
    page = 1,
    pageSize = 10,
    sort,
    fields,
  }: {
    cciCode: string,
    masterTypeCode: string,
    masterCode?: string,
    masterCodeStartswith?: string,
    page?: number,
    pageSize?: number,
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string,
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string,
  }): CancelablePromise<{
    Items?: Array<MasterData>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/company/{cci_code}/master/{master_type_code}',
      path: {
        'cci_code': cciCode,
        'master_type_code': masterTypeCode,
      },
      query: {
        'master_code': masterCode,
        'master_code__startswith': masterCodeStartswith,
        'page': page,
        'page_size': pageSize,
        'sort': sort,
        'fields': fields,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    });
  }

}
